import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Search: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25">
      <defs>
        <path id="a" d="M10 20a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z" />
        <mask id="b" width="20" height="20" x="0" y="0" fill="#fff" maskUnits="objectBoundingBox">
          <use xlinkHref="#a" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" stroke={iconColor} strokeDasharray="0,0" transform="translate(.85)">
        <use xlinkHref="#a" strokeWidth="4" mask="url(#b)" />
        <path strokeWidth="2" d="m17.02 17.08 7.07 7.07" />
      </g>
    </svg>
  )
}

Search.defaultProps = {
  iconColor: 'currentColor'
}

export default Search
