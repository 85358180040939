import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Star: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_238_1538)">
        <path
          d="M10.3747 2.96477C9.62068 1.45675 9.24368 0.702743 8.75061 0.454007C8.27848 0.215852 7.72141 0.215852 7.24928 0.454006C6.75621 0.702743 6.3792 1.45675 5.6252 2.96477C5.42058 3.37398 5.31828 3.5786 5.17882 3.73858C4.9991 3.94474 4.77141 4.10351 4.51583 4.20087C4.31749 4.27643 4.09428 4.30124 3.64784 4.35084C2.0931 4.52359 1.31574 4.60996 0.932564 4.9263C0.438113 5.3345 0.221667 5.99014 0.375907 6.6125C0.495436 7.09478 1.07727 7.63504 2.24095 8.71564C2.60345 9.05224 2.7847 9.22051 2.90316 9.41211C3.0865 9.70871 3.17224 10.0552 3.14828 10.4031C3.1328 10.6278 3.05211 10.8578 2.89074 11.3177C2.36627 12.8124 2.10404 13.5598 2.20288 14.019C2.35692 14.7348 2.95999 15.2662 3.68941 15.3289C4.15743 15.3691 4.86585 15.0149 6.2827 14.3065L6.80734 14.0442C7.24468 13.8255 7.46328 13.7162 7.69261 13.6732C7.89574 13.6351 8.10414 13.6351 8.30728 13.6732C8.53661 13.7162 8.75521 13.8255 9.19254 14.0442L9.71721 14.3065C11.134 15.0149 11.8425 15.3691 12.3105 15.3289C13.0399 15.2662 13.6429 14.7348 13.797 14.019C13.8958 13.5598 13.6336 12.8124 13.1091 11.3177C12.9477 10.8578 12.8671 10.6278 12.8516 10.4031C12.8277 10.0552 12.9133 9.70871 13.0967 9.41211C13.2152 9.22051 13.3964 9.05224 13.7589 8.71564C14.9226 7.63504 15.5045 7.09478 15.6239 6.61249C15.7782 5.99014 15.5617 5.3345 15.0673 4.9263C14.6841 4.60996 13.9067 4.52359 12.352 4.35084C11.9056 4.30124 11.6824 4.27643 11.4841 4.20087C11.2285 4.10351 11.0008 3.94474 10.8211 3.73858C10.6816 3.57859 10.5793 3.37398 10.3747 2.96477Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_238_1538">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

Star.defaultProps = {
  iconColor: 'currentColor'
}

export default Star
