import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Envelope: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25956 6.29124C2.36488 5.14647 3.32775 4.25 4.5 4.25H19.5C20.5259 4.25 21.3915 4.93663 21.6622 5.8754L11.9849 11.1539L2.25956 6.29124ZM2.25 7.96351V17.5C2.25 18.7426 3.25736 19.75 4.5 19.75H19.5C20.7426 19.75 21.75 18.7426 21.75 17.5V7.53613L12.3592 12.6584C12.1436 12.776 11.8842 12.7806 11.6646 12.6708L2.25 7.96351Z"
        fill={iconColor}
      />
    </svg>
  )
}

Envelope.defaultProps = {
  iconColor: 'currentColor'
}

export default Envelope
