import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Water: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  // Filled and outlined state is the same for this icon
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        stroke={iconColor || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2 8c1.98 1.37 3.02 1.4 5 0 1.98-1.32 3.05-1.3 5 0 1.95 1.33 3.1 1.31 5 0 1.95-1.32 3.05-1.35 5 0M2 12c1.98 1.37 3.02 1.4 5 0 1.98-1.32 3.05-1.3 5 0 1.95 1.33 3.1 1.31 5 0 1.95-1.32 3.05-1.35 5 0M2 16c1.98 1.37 3.02 1.4 5 0 1.98-1.32 3.05-1.3 5 0 1.95 1.33 3.1 1.31 5 0 1.95-1.32 3.05-1.35 5 0"
      />
    </svg>
  )
}

Water.defaultProps = {
  backdropColor: '#23D5AE',
  hasBackdrop: false,
  iconColor: 'currentColor',
  isFilled: false
}

export default Water
