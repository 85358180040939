import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Wind: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M14.3333 13.5C14.3333 14.6967 13.2889 15.6667 11.9999 15.6667C10.7109 15.6667 9.6666 14.6967 9.6666 13.5C9.6666 12.3033 10.7109 11.3333 11.9999 11.3333C13.2889 11.3333 14.3333 12.3033 14.3333 13.5ZM4.02327 12.3273C4.2566 12.3667 4.49127 12.2083 4.52993 11.971C4.84193 9.99166 5.89427 8.25033 7.4926 7.10333C7.6876 6.945 7.72593 6.70767 7.57027 6.51C7.41427 6.312 7.1806 6.27267 6.98593 6.431C5.1926 7.69667 3.9846 9.63566 3.6726 11.8523C3.6336 12.0893 3.7896 12.288 4.02327 12.3273ZM19.1423 11.973C19.1809 12.209 19.4136 12.3663 19.6456 12.3273C19.8776 12.288 20.0326 12.0513 19.9939 11.8153C19.6073 9.64866 18.4453 7.71933 16.6646 6.419C16.4713 6.26167 16.1999 6.34067 16.0846 6.49767C15.9293 6.69433 16.0073 6.97033 16.1613 7.08767C17.7873 8.30966 18.8333 10.0423 19.1423 11.973ZM15.0386 20.728C13.1636 21.3967 11.1286 21.3613 9.2546 20.693C9.0156 20.623 8.77593 20.693 8.69627 20.904C8.61693 21.1147 8.69627 21.3267 8.93527 21.3967C10.9699 22.171 13.2439 22.206 15.3976 21.4667C15.6366 21.3967 15.7166 21.15 15.6366 20.974C15.5173 20.763 15.2379 20.6573 15.0386 20.728ZM10.6666 16.1873C9.7466 15.6833 9.10593 14.752 9.0266 13.6667C5.34493 14.3653 1.66393 16.653 2.02393 19.7943C4.86493 21.4617 8.5466 18.9797 10.6666 16.1873ZM14.9733 13.6667C14.8936 14.7527 14.2533 15.684 13.3333 16.1883C15.5339 19.0977 19.1749 21.4253 21.9756 19.796C22.3356 16.654 18.6143 14.3657 14.9733 13.6667ZM13.4266 11C14.0033 9.64233 14.3333 8.091 14.3333 6.694C14.3333 4.63833 13.6329 2.815 11.8199 2C8.81227 3.39633 8.85327 7.62433 10.2543 11C11.2843 10.456 12.4373 10.456 13.4266 11Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Wind.defaultProps = {
  backdropColor: '#23D5AE',
  hasBackdrop: false,
  iconColor: 'currentColor',
  isFilled: false
}

export default Wind
