import { useEffect, useState } from 'react'

import { UseWindowSizeReturnType } from 'hooks/types'

/**
 * Custom hook that returns the current width, height & device checks
 *
 * @returns {UseWindowSizeReturnType}
 */
const useWindowSize = (customWidth?: number): UseWindowSizeReturnType => {
  // Local state
  const [size, setSize] = useState({
    height: 0,
    width: 0
  })

  /**
   * Triggered on every layout change (scroll, resize, ...)
   */
  useEffect(() => {
    const updateSize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return {
    ...size,
    isMobile: size.width <= 600,
    isSmaller: customWidth ? size.width <= customWidth : false,
    isTablet: size.width <= 900,
    isDesktop: size.width >= 1280
  }
}

export default useWindowSize
