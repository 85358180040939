import { FC } from 'react'
import { SvgProps } from './types'

const ArrowLeft: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10 5L3 11.9999M3 11.9999L10 18.9999M3 11.9999H21"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ArrowLeft.defaultProps = {
  iconColor: 'currentColor'
}

export default ArrowLeft
