import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Question: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="8" fill={iconColor} />
      <path
        fill="#fff"
        d="M8.4782 4.2773c1.1449 0 2.0201.2323 2.6258.697.6056.4645.9084 1.1033.9084 1.9164 0 1.4767-1.091 2.3063-3.2729 2.4889v.5475H7.1217V8.1102c1.037 0 1.7879-.087 2.2525-.2613.4646-.1825.6969-.4854.6969-.9085 0-.3484-.1494-.6222-.448-.8213-.2904-.1991-.7177-.2987-1.2818-.2987-1.037 0-1.9662.2946-2.7876.8836L4.832 5.3227c.9706-.697 2.186-1.0454 3.6462-1.0454Zm-1.344 6.944c.2157-.1991.4812-.2986.7964-.2986.3153 0 .5766.0995.784.2986.2157.1991.3236.4522.3236.7591 0 .307-.1079.56-.3236.7592-.2074.1991-.4687.2986-.784.2986-.3152 0-.5807-.0995-.7964-.2986-.2074-.1992-.3111-.4522-.3111-.7592 0-.3069.1037-.56.311-.7591Z"
      />
    </svg>
  )
}

Question.defaultProps = {
  iconColor: '#ABAEC8'
}

export default Question
