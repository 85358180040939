import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Receipt: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 4.5C4.25 3.25736 5.25736 2.25 6.5 2.25H17.5C18.7426 2.25 19.75 3.25736 19.75 4.5L19.75 21.0001C19.75 21.2412 19.6341 21.4676 19.4385 21.6085C19.243 21.7495 18.9915 21.7878 18.7628 21.7116L16.0575 20.8098L14.3354 21.6709C14.1243 21.7765 13.8757 21.7765 13.6646 21.6709L12 20.8386L10.3354 21.6709C10.1243 21.7765 9.87574 21.7765 9.66459 21.6709L7.94245 20.8098L5.23717 21.7116C5.00846 21.7878 4.75704 21.7495 4.56147 21.6085C4.36589 21.4676 4.25 21.2412 4.25 21.0001V4.5ZM7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H16C16.4142 9.25 16.75 9.58579 16.75 10C16.75 10.4142 16.4142 10.75 16 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10ZM8 6.25C7.58579 6.25 7.25 6.58579 7.25 7C7.25 7.41421 7.58579 7.75 8 7.75H12C12.4142 7.75 12.75 7.41421 12.75 7C12.75 6.58579 12.4142 6.25 12 6.25H8ZM7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H16C16.4142 12.25 16.75 12.5858 16.75 13C16.75 13.4142 16.4142 13.75 16 13.75H8C7.58579 13.75 7.25 13.4142 7.25 13ZM8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H16C16.4142 16.75 16.75 16.4142 16.75 16C16.75 15.5858 16.4142 15.25 16 15.25H8Z"
        fill={iconColor}
      />
    </svg>
  )
}

Receipt.defaultProps = {
  iconColor: 'currentColor'
}

export default Receipt
