import { FC } from 'react'
import icon from 'components/icon/paths'
import { IconProps } from './types'

const Icon: FC<IconProps> = ({ size, color, name, fillRule, viewBox, ...rest }) => (
  <svg viewBox={viewBox ?? `0 0 ${size} ${size}`} width={size} height={size} {...rest}>
    {typeof icon[name] === 'string' ? <path d={icon[name] as string} fill={color} fillRule={fillRule} /> : icon[name]}
  </svg>
)

Icon.defaultProps = {
  color: 'currentColor',
  size: 24
}

export default Icon
