import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const TextBoxSearch: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        fill={iconColor}
        d="M12.9167 10c2.0833 0 3.75 1.6667 3.75 3.75 0 .7333-.2084 1.425-.575 2l2.5666 2.5833L17.5 19.4917l-2.6-2.5584a3.7472 3.7472 0 0 1-1.9833.5667c-2.0834 0-3.75-1.6667-3.75-3.75s1.6666-3.75 3.75-3.75Zm0 1.6667a2.0834 2.0834 0 1 0 0 4.1667 2.0834 2.0834 0 0 0 0-4.1667ZM5.8333 12.5v1.6667H7.5c.1167 1.2916.6667 2.45 1.5083 3.3333H4.1667c-.925 0-1.6667-.75-1.6667-1.6667V4.1667A1.661 1.661 0 0 1 4.1667 2.5h11.6666A1.6664 1.6664 0 0 1 17.5 4.1667v6.6916c-.9583-1.5166-2.65-2.525-4.5833-2.525-1.0584 0-2.05.3084-2.8834.8334h-4.2v1.6666h2.5c-.3.5-.55 1.0667-.6916 1.6667H5.8333Zm8.3334-5V5.8333H5.8333V7.5h8.3334Z"
      />
    </svg>
  )
}

TextBoxSearch.defaultProps = {
  iconColor: 'currentColor'
}

export default TextBoxSearch
