import Head from 'next/head'
import { SeoProps } from './types'
import { formatSeoTitle } from 'utils/seo'

const Seo = ({ title, description, image, noindex, canonical }: SeoProps) => {
  // Constants
  const fullTitle = formatSeoTitle(title)
  return (
    <Head>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} key="title" />
      {!!description && <meta name="description" content={description} />}
      {!!description && <meta property="og:description" content={description} />}
      {!!image && <meta property="og:image" content={image} key="image" />}
      {!!noindex && <meta name="robots" content="noindex" />}
      {!!canonical && <link rel="canonical" href={canonical} />}
    </Head>
  )
}

export default Seo
