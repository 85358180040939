import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Warning: FC<SvgProps> = ({ iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1261 3.5735L2.82552 18.5144C2.45523 19.1809 2.9372 20 3.69968 20H20.3007C21.0631 20 21.5451 19.1809 21.1748 18.5144L12.8744 3.5735C12.4934 2.88769 11.5071 2.88769 11.1261 3.5735ZM12.0001 14C11.4479 14 11.0001 13.5299 11.0001 12.95V9.05002C11.0001 8.47012 11.4479 8.00002 12.0001 8.00002C12.5524 8.00002 13.0001 8.47012 13.0001 9.05002V12.95C13.0001 13.5299 12.5524 14 12.0001 14ZM12.0068 17C12.5554 17 13.0001 16.5523 13.0001 16C13.0001 15.4477 12.5554 15 12.0068 15H11.9935C11.4449 15 11.0001 15.4477 11.0001 16C11.0001 16.5523 11.4449 17 11.9935 17H12.0068Z"
        fill={iconColor}
      />
    </svg>
  )
}

Warning.defaultProps = {
  iconColor: 'currentColor'
}

export default Warning
